@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

::-webkit-scrollbar {
  width: 20px !important;
  height: 4px !important;
}
/* Track */
::-webkit-scrollbar-corner {
  background-color: transparent;
}
/* Handle */
::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  /* background-clip: padding-box; */
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.15);
  /* box-shadow: inset -1px -1px 0px rgb(0 0 0 / 5%),
    inset 1px 1px 0px rgb(0 0 0 / 5%); */
}
.titoloAccessPin-fs1_5{
  font-size: 1.5rem;
}

.testo-fs1_2{
  font-size: 1.8rem;
}
/* Stili per schermi fino a 1366px (ad esempio, notebook da 13 pollici) */
@media (max-width: 1366px) {
  .app {
    max-width: 60%;
    margin: 0 auto;
    
  }
}

/* Stili per schermi fino a 1920px (ad esempio, notebook da 15 pollici) */
@media (max-width: 1920px) {
  .app-container {
    max-width: 50%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

/* Stili per schermi fino a 2560px (ad esempio, notebook da 17 pollici) */
@media (max-width: 2560px) {
  .app-container {
    max-width: 60%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

/* Stili per schermi fino a 3840px (ad esempio, schermi 4K) */
@media (max-width: 3840px) {
  .app-container {
    max-width:30%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
.cl-black{
  color:black
}
input[type="tel"] {
  width: 2em !important;
}

.fontfamily{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden!important;
}

img{
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* REGION MEETINGS */
.meetingList{
  display: flex;
  justify-content: start;
  margin: 0!important;
}


/* REGION LAYOUT */
.appBarStyle {
  position: absolute;
  width: 100%;
  
}

.appBarLogoSize {
  width: 10rem;
}

.backgroundImage {
  background-image: url("assets/Sfondo_texture.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: repeat-x;
  width: 100%;
}

/* REGION LOADER*/
.backdrop {
  color: #CCCCCC !important;
  z-index: 1500;
}

.loader {
  color: white !important;
}

/* REGION VISUALIZATION */
.centerVisualization {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column!important;

}

.centerVisualizationLogo {
  display: flex;
  align-items: center;
  justify-content: center;bottom:13%;
}

.spaceEvenlyVisualization {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.scrollable {
  overflow-x: hidden;
  overflow-y: scroll;
}

/* REGION TELECOMANDO */
.buttonTelecomando {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  background-color: white;
  width: 6.5rem;
  height: 6.5rem;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  transition: transform 0.3s, box-shadow 0.3s;
 }

@media only screen and (max-width: 600px) {
  .buttonTelecomando {
    transform: scale(1); 
  }
}
.tasto{
  height: auto;
  position: relative;
}
.btn-text-size{
  font-size: 1.5rem;
}

.bg_blue_cl_white{
  background-color: #002E5D!important;
  color: white!important;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  border-radius: 20px!important;
  padding: 0px 15px 2px 15px!important;
}
.w-40{
  width: 35%!important;
}

.titlegray{
  color: rgba(0, 0, 0, 0.15)!important
}

.bluetext_elencomeeting{
  color:#002E5D;
  font-weight: 400;

}

.bluetext_nomeSala_nomeAccount{
  color: #002E5D;
  font-size: 1.8rem;
  font-weight: 700;
}

.buttonTelecomando:active,
.buttonTelecomando.active {
  /* transform: scale(0.9); */
  opacity:0.5
}
.comandoInviatoMessage{
  color: #002E5D;
  font-weight: 600;
  text-align: center;
  position: absolute;
  top:90%;
  line-height: 2.5!important;
}

.comadoInviatoMessage_note{
  color: #002E5D;
  font-weight: 600;
  text-align: center;
  position: absolute;
  top: 100%;
  line-height: 1.5!important;
}

.bg-blue{
  background-color: #002E5D;
}
.btnFixed{
  position: fixed;
  bottom: 1.5rem;
}
.h5_notebook{
  color:#002E5D;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.min-w-50{
  min-width: 50% !important;
}

.imageScaled:active,
.imageLessScaled:active {
  transform: scale(0.8);
  transition: transform 0.2s;
}

/* REGION SELECT ACCOUNT */

.buttonSelectAccount {
  padding: 0.3rem 2rem;
  width: 10rem;
  height: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
label{
  display: flex!important;
  justify-content: center!important;
}
.accountName {
  font-size: 1.3rem !important;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  white-space: nowrap;
  /* Impedisce il wrapping del testo */
  overflow: hidden !important;
  /* Nasconde il testo in eccesso */
  text-overflow: ellipsis !important;
  /* Aggiunge i puntini di ellipsis */
  max-width: 13rem;
}

/* REGION IMAGE */
.imageScaled {
  transform: scale(0.5);
}

.imageLessScaled {
  transform: scale(0.70);
}

.imageHalfSize {
  width: 50%;
  height: 50%;
}

.imageHalfWidth {
  width: 50%;
}

.image30Width {
  width: 30%;
}

.imageQuarterWidth {
  width: 25%;
}

.imageQuarterSize {
  width: 25%;
  height: 25%;
}

.imageThreeQuarterSize {
  width: 100%;
}

.imageFullSize {
  width: 100%;
  height: 100%;
}

.imageFullSize.disabled {
  opacity: 0.5; 
  cursor: not-allowed; 
}

.btnHover:hover{
  cursor: pointer;
  background-color: #002E5D!important;
  border-radius: 20px!important;
  padding: 0px!important;
}

.hovered:hover{
  cursor: pointer;
}

.imageScaled:hover{
  cursor: pointer;
}
.imageLessScaled:hover{
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .imageAutoSize {
    transform: scale(0.7); 
  }
}

.buttonSelectAccount.blueBackground.disabled{
  opacity: 0.5; 
  cursor: not-allowed; 
}

.imageAutoSize {
  width: 100%;
  height: auto;
}


.previewSlide {
  width: 100%;
  max-height: 30vh;
}

.imageHalfAutoSize {
  width: 50%;
  height: auto;
}

/* REGION TEXT */
.blueBackground {
  background-color: #002E5D;
}

.boldBlueText {
  color: #002E5D;
  font-weight: bold !important;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.blueText{
  color: #002E5D;

}

.colorMessageDialog{
  color:black;
  font-size:1.5rem !important;
}

.boldWhiteText {
  color: white;
  font-weight: bold !important;
}

.centerRedText {
  color: #B70303;
  text-align: center;
}

.fontWeight500 {
  font-weight: 500 !important;
}

.fontWeight600 {
  font-weight: 600 !important;
}

.textUppercase {
  text-transform: uppercase;
}

/* REGION IFRAME */
.iFrameTeletabber {  
  width: 100%;
  overflow: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iFrameTeletabber::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

.iFrameTeletabber::-webkit-scrollbar-corner {
  background-color: transparent;
}
.centerBtn{
  display: flex;
  justify-content: center;
}

.bottomFixed_notebook{
  color: white;
  height: 7vh;
  display: flex !important;
  margin: 0 auto !important;
  align-items: center;
  font-size: x-large;
  font-weight: 400;
  justify-content: center;
  padding: 0px;
}
.bottomFixed {
  position: fixed;
  left: 0;
  bottom: 0;
  flex-direction: column;
  width: 100%;
  color: white;
  height: 7vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: x-large;
  font-weight: 400;
}

.ellipsisTypo {
  white-space: nowrap;
  /* Impedisce il wrapping del testo */
  overflow: hidden !important;
  /* Nasconde il testo in eccesso */
  text-overflow: ellipsis !important;
  /* Aggiunge i puntini di ellipsis */
  /* max-width: calc(100vw -20px); */
}
.label-cst-margin {
  max-height: 40dvh;
  min-height: 10dvh;
  width: 100%;
  display: flex;
  justify-content: center;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .ellipsisTypo {
    max-width: 24.1rem;
  }
  .imageAutoSizeAccessTeams{
    scale: 0.5!important;
  }
  .imageAutoSizeBtnAccessGoBack{
    scale:0.7!important;
  }
  .label-cst-margin > span:first-child {
    /* Styling for unchecked radio */
    color: #002E5D;
    scale: 1;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .ellipsisTypo {
    max-width: 43rem; 
  }
  .imageAutoSize{
    scale: 0.9!important;
    margin-top: 2rem;
  }
  .imageAutoSizeBtn{
    scale: 0.7!important;
  }
  .imageAutoSizeAccessTeams{
    scale: 0.7!important;
  }
  .cst-align-vertically{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .label-cst-margin > span:first-child {
    /* Styling for unchecked radio */
    color: #002E5D;
    scale: 2;
    margin: 0px 30px 0px 55px
  }
  .cst-font-size{
    font-size: 1.3rem!important;
  }
}
.imageAutoSizeAccessTeams{
  scale:1;
}
.scaleout{
  scale:0.5
}

.scaleoutbtn{
  scale:0.7
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .ellipsisTypo {
    max-width: 32rem; 
  }
  .imageAutoSizeAccessTeams{
    scale: 1.2;
  }
  .meetingList{
    margin-top: 5rem!important;
  }
  .list{
    margin-top: 5rem!important;
    margin-bottom: 2rem!important;
  }
  .btn-cst{
    scale:1.5
  }
  .container-home{
    margin-top: 3rem;
  }
  .btn-cst-home{
    margin-top: 3rem!important;
  }
  .cst-font-size{
    font-size: 1.3rem!important;
    padding-left: 30px;
  }
  .label-cst-margin{
    margin-top:2.2rem!important;
  }
  .imageAutoSize{
    scale: 0.8!important;
  }
  .cst-align-vertically{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .label-cst-margin > span:first-child {
    /* Styling for unchecked radio */
    color: #002E5D;
    scale: 2;
    margin: 0px 30px 0px 55px
  }
  .title,.subTitle{
    font-size: 3rem!important;
  }
}


/* Large devices (laptops/desktops, 1024px and up) */
@media only screen and (min-width: 1025px) {
  .ellipsisTypo {
    max-width: 64rem; 
  }
}

@media (min-width: 1824px) and (min-height: 2736px)  {
  /* Stili da applicare quando la risoluzione è >= breakpointY e breakpointX */
  .title {
    font-size: 8rem!important;
  }
  .colorMessageDialog{
    font-size: 4rem!important;
  }
  .primaryButton{
    font-size: 3rem!important;
    width:50%!important;
    margin: 25px!important;
  }
  h5{
    font-size: 9rem!important;
  }
  .title-header{
    font-size: 5rem!important;
  }
  .imageAutoSize{
    scale: 0.8!important;
  }
  .imageFullSize{
    scale:1.6!important;
    margin-bottom: 3rem;
  }
  .appBarLogoSize {
    width: 30rem!important;
  }
  .imageAutoSizeAccessTeams{
    scale: 2.2!important;
    margin-top: 20rem!important;
  }
  .btn-cst-home{
    margin-top: 17rem!important;
  }
  .imageAutoSizeAccessTeamsHome{
    scale: 0.8!important;
    margin-top:4rem!important;
  }
  .imageAutoSizeBtn{
    scale:1.2!important;
    margin-top:3rem!important
  }
  .goBackBtn{
    font-size:7rem
  }
  .imageAutoSizeBtnAccessGoBack{
    scale:0.9!important;
  }
  .buttonSelectAccount{
    width: 20%;
    scale:2;
    padding:1.5rem;
    margin-top: 6rem;
    margin-bottom: 8rem;
  }
  .accountName{
    font-size:2rem!important;
    line-height: unset!important;
  }
  .image30Width{
    margin-top:6rem!important;
  }
  .btn-cst{
    margin-top:1.5rem!important;
    scale:2;
    max-width: 20rem!important;
    width:16rem!important;
    padding:2.5rem!important;
    font-size: 2rem!important;
    border-radius: 45px!important;
  }
  .imageThreeQuarterSize{
    scale:2!important;
  }
  .meetingList{
    margin-top: 15rem!important;
    display: flex!important;
    justify-content: center!important;
  }
  .subTitle{
    font-size: 5rem!important;
  }
  .cst-font-size{
    font-size: 6rem!important;
  }
  .list {
    margin-top: 15rem!important;
    padding-left:2rem!important
  }
  .buttonTelecomando{
    scale:2.6;
    /* margin-top:3rem!important;
    margin-bottom:6rem!important; */
  }
  .buttonTelecomandoMano{
    scale:3.2!important
  }
  .buttonTelecomandoAbbandona{
    scale:3.2!important
  }
  .tasto{
    padding-top:0px !important;
  }
  .tasto_note{
    height: unset;
    padding-top:0px !important;
    position: relative;

  }

  .tasto_note:hover{
    cursor: pointer;
    background-color: #002E5D;
  }

  .tasto:hover{
    cursor: pointer;
    background-color: #002E5D;
  }
  .buttonTelecomandoLess{
    scale:1.5
  }
  .teamslogo{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .comandoInviatoMessage{
    top: 65%!important;
    font-size: 2.2rem!important;
  }
}



/*RECUPERO SESSIONE REGION*/
.PINCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.PINInput {
  justify-content: center!important;
  align-items: center!important;
}

/* REGION BUTTONS */
.primaryButton {
  background-color: #002E5D !important;
  color: white !important;
  padding: 6px !important;
  border-radius: 4px !important;
}

.popup-blocked-notification{
  color:#002E5D;
  text-align: justify;
  text-justify: distribute;
  word-spacing: 0.1em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.meeting-option {
  background-color: #E6E6E6;
  border: 2px solid rgb(207, 207, 207);
  width: 100%;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.meeting-option-today{
  background-color: #E6E6E6;
  border: 2px solid rgb(207, 207, 207);
  width: 100%;
  padding: 10px;
}
.today{
  border-radius: 50px;
  text-align: center;
  padding: 10px;
  width: auto;
  cursor:default;
}
.w-70{
  width: 70%!important;
}
.meeting-option:hover {
  background-color: #c1c1c1;
}

.meeting-option.selected {
  border-color: #002E5D /* Cambia il colore del bordo per l'elemento selezionato */
}